import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment, { Moment } from "moment";
import { TimeSlot } from "./types";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  available_date: Moment;
  start_time: Moment | null;
  end_time: Moment | null;
  id: string | number;
  token: string;
  appointmentsList: TimeSlot[];
  showPicker: boolean;
    activeTab:string;
    allRides:any;
    scheduledRides:any;
    acceptedRides:any;
    completedRides:any;
    cancelledRides:any;
    popupOpen:boolean;
    selectedRide:any;
    isToastOpen:boolean;
    rideRejected:boolean;
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AppointmentsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAppointmentsListApiCallId?: string;
  RidesApiCallId?:string;
  AcceptRideApiCallId?:string;
  RejectRideApiCallId?:string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    let endTime = new Date();
    endTime.setMinutes(endTime.getMinutes() + 30);

    this.state = {
      // Customizable Area Start
      id: 0,
      start_time: moment(new Date()),
      end_time: moment(endTime),
      available_date: moment(new Date()),
      appointmentsList: [],
      token: "",
      showPicker: false,
        activeTab:"1",
        allRides:[ ],
        scheduledRides:[ ],
        completedRides:[],
        cancelledRides:[],
        acceptedRides:[],
        popupOpen:false,
        selectedRide:null,
        isToastOpen:false,
        rideRejected:false,
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    await super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    const token = localStorage.getItem("token")
    if (token) {
      this.getSchedulredRideApi(token)
      this.setState({token})
    }
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };
  getAttribute(row:any, key:any, defaultValue = 'N/A') {
    return row?.attributes?.[key] ?? defaultValue;
  }
  getAttributeValue = (key:any, defaultValue = 'N/A') => {
    return this.state.selectedRide?.attributes?.[key] || defaultValue;
  };
  receive = async (from: String, message: Message) => {
    // Customizable Area Start
    runEngine.debugLog("Message Recieved", JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      runEngine.debugLog("API Message Recived", message);

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.RidesApiCallId) {
        if (responseJson && responseJson.data) {
          const statuses = ["In Progress","Scheduled",];
          this.setState({ allRides: responseJson.data })
          this.setFilteredRides()
        }
        if (responseJson && responseJson.errors[0]==="No scheduled rides available") {
          this.setState({ allRides: [] })
        }
      }
      if (apiRequestCallId === this.AcceptRideApiCallId) {
        if (responseJson && responseJson.meta.message==="Status updated successfully") {
          this.setState({ popupOpen: false,isToastOpen:true,selectedRide:null,rideRejected:false })
          const token = localStorage.getItem("token")
          if (token) {
            this.getSchedulredRideApi(token)
          }
        }
      }
      if (apiRequestCallId === this.RejectRideApiCallId) {
        if (responseJson && responseJson.meta.message==="Status updated successfully") {
          this.setState({ popupOpen: false,rideRejected:true,isToastOpen:true,selectedRide:null});
          const token = localStorage.getItem("token")
          if (token) {
            this.getSchedulredRideApi(token)
          }
        }
      }
    }
    // Customizable Area End
  };

  // Customizable Area Start
  handleClose = () => {
    this.setState({ isToastOpen: false })
}
setFilteredRides = () => {
  const { allRides } = this.state;

  const scheduledRides = allRides.filter(
    (item:any) => item.attributes && item.attributes.status === "scheduled"
  );
  const completedRides = allRides.filter(
    (item:any) => item.attributes && item.attributes.status === "completed"
  );
  const cancelledRides = allRides.filter(
    (item:any) => item.attributes && item.attributes.status === "cancelled"
  );
  const acceptedRides = allRides.filter(
    (item:any) => item.attributes && item.attributes.status === "accepted"
  );

  this.setState({
    scheduledRides,
    completedRides,
    cancelledRides,
    acceptedRides
  });
};
  getSchedulredRideApi = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.RidesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getrideendpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  AcceptRide=()=>{
    const token = localStorage.getItem("token")
    const header = {
      token: token,
    };
    const formdata = new FormData();
    formdata.append("status","accepted");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.AcceptRideApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.acceptrideendpoint}${this.state.selectedRide?.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  RejectRide=()=>{
    const token = localStorage.getItem("token")
    const header = {
      token: token,
    };
    const formdata = new FormData();
    formdata.append("status","cancelled");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.RejectRideApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.acceptrideendpoint}${this.state.selectedRide?.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleActionClick = (action:any) => {
    if (action === "Add Notes") {
      this.navigateToAddNote();
    } 
  };
  getButtonText = (status: string): string => {
    switch (status.toLowerCase()) {
      case 'scheduled':
        return 'Add Notes';
      case 'accepted':
        return 'Update';
        case 'cancelled':
          return 'Update';
          case 'completed':
            return 'Update';
      default:
        return 'Unknown Status'; // Handle unexpected cases
    }
  };
  handlePopup=(row: any)=>{
    this.setState({rideRejected:false})
    const status = row?.attributes?.status;
    if (!status) {
      this.setState({ popupOpen: false }); // Handle undefined or unexpected cases
      return;
    }
    switch (status) {
      case 'scheduled':
        this.setState({ popupOpen: true,selectedRide:row });
        break;
      case 'accepted':
        this.setState({ popupOpen: true,selectedRide:row });
        break;
      case 'cancelled':
        this.setState({ popupOpen: true,selectedRide:row });
        break;
      default:
        this.setState({ popupOpen: false }); // Handle unexpected cases
        break;
    }
  }
  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);

    return formattedDate;
  }

  formatTime = (timeString: string): string => {
    try {
      const [, timePart] = timeString.split('T');
      let [hours, minutes] = timePart.split(':');
      let numericHours = parseInt(hours, 10);
      let numericMinutes = parseInt(minutes, 10);

      const period = numericHours >= 12 ? 'PM' : 'AM';
      const adjustedHours = numericHours % 12 || 12;
      const adjustedMinutes = numericMinutes.toString().padStart(2, '0');

      return `${adjustedHours}:${adjustedMinutes} ${period}`;
    } catch (error) {
      return 'Invalid time';
    }
  };
  closeDialog=()=>{
    this.setState({popupOpen:false,selectedRide:null,rideRejected:false})
  }
  navigateToAddNote=()=>{
    const navigate: Message = new Message(getName(MessageEnum.NavigationMessage));
    navigate.addData(getName(MessageEnum.NavigationTargetMessage), "ChatRiderDriver");
    navigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigate);
  } 
  getAppointmentList = (token: string) => {
    const header = {
      "Content-Type": configJSON.appointmentApiContentType,
      token,
    };

    const searchParams = {
      service_provider_id: "1",
      availability_date: this.state.available_date.format(
        configJSON.serverFormat
      ),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAppointmentsListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.appointmentAPiEndPoint}?${new URLSearchParams(
        searchParams
      ).toString()}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAppointmentListAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  update(value: Partial<{ [K in keyof S]: S[K] }>) {
    this.setState((state) => ({ ...state, ...value, showPicker: false }));
  }

  getDate(value: string, format: string) {
    return moment(value).format(format);
  }

  toMomentDate(value?: string | Date | null, format?: string) {
    return moment(value, format);
  }

  toDateFromMoment(value: Moment) {
    return value.toDate();
  }

  setactiveTab=(active:string)=>{
    this.setState({activeTab:active})
   }

  navigateToAddAppointment() {
    // Merge Engine - Navigation - Start
    const navigationMessage = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'AddAppointment'
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(navigationMessage);
    // Merge Engine - Navigation - End
  }
  getCellBackgroudColor=(status:any)=>{
    if (status === 'accepted') {
      return '#D1FAE5'; 
    } else if (status === 'scheduled') {
      return '#FEF3C7'; 
    } else if (status === 'cancelled') {
      return 'pink'; 
    } else if (status === 'completed') {
      return '#D1FAE5'; 
    }else {
      return ''; 
    }

  }
  getCellTextColor=(status:any)=>{
    if (status === 'accepted') {
      return '#059669'; 
    } else if (status === 'scheduled') {
      return '#D97706'; 
    }else if (status === 'cancelled') {
      return 'red'; 
    }else if (status === 'completed') {
      return '#059669'; 
    }  else {
      return ''; 
    }
  }
  getSampleData=(currenttab:string)=>{
      if ( currenttab=== "1") {
      return this.state.allRides; 
    } else if (currenttab === "2") {
      return this.state.scheduledRides; 
    } else if (currenttab === "3") {
      return this.state.completedRides; 
    }  else if (currenttab === "4") {
      return this.state.cancelledRides; 
    } else if (currenttab === "5") {
      return this.state.acceptedRides; 
    }else {
      return []; // Default case when no matching tab is found
    }
  }
  toUpperCase = (input: string): string => {
    return input.toUpperCase();
  };
  // Customizable Area End
}
