
// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
const carData = [
    { id: 1, name: 'Sedan', image: 'car1.jpg', distance: '15 km', price: '22' },
    { id: 2, name: 'SUV', image: 'car2.jpg', distance: '20 km', price: '80' },
    { id: 2, name: 'Hatchback', image: 'car2.jpg', distance: '20 km', price: '80' },
    { id: 2, name: 'Minivan', image: 'car2.jpg', distance: '20 km', price: '80' },
    { id: 2, name: 'Other', image: 'car2.jpg', distance: '20 km', price: '80' },
    // Add more cars as needed
];;
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    opensearch: () => {}
    setactive: () => {}
    planride: (data:any) => {}
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    helpCentreFaq: any;
    expandedField3: boolean;
    expandedField4: boolean;
    expandedField5: boolean;
    expandedField6: boolean;
    isStartDestOpen: boolean;
    home: any;
    work: any;
    location: any;
    selectedCar: any
    isCarPopUpOpen: boolean;
    LocationErrrorPopUp: boolean;
    InternetErrorPopUp: boolean;
    startlocation: string;
    destinationlocation: string;
    showstartlocation: boolean;
    showendLocation: boolean;
    startlocationerror: boolean;
    endlocationerror: boolean;
    cartypeerror: boolean;
    isToastOpen: boolean;
    rideId:any;
    ordersummarydetails:any;
    fromTripHistoryScreen:boolean;
    // Customizable Area End
}

interface SS {
    id: any;
}
export default class PlanYourRideController extends BlockComponent<Props, S, SS> {
    getHelpCentreApiCallId: any;
    orderSummaryApiCallId:any;
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.handleField5 = this.handleField5.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
        ];
        this.state = {
            helpCentreFaq: [],
            expandedField3: false,
            expandedField4: false,
            expandedField5: false,
            expandedField6: false,
            isStartDestOpen: false,
            showstartlocation: false,
            showendLocation: false,
            startlocationerror: false,
            endlocationerror: false,
            cartypeerror: false,
            isToastOpen: false,
            startlocation: "",
            destinationlocation: "",
            home: [{ name: 'Place 1', description: 'Description of Place 1' },
            ],
            work: [{ name: 'Place 1', description: 'Description of Place 1' },
            ],
            location: [{ name: 'Place 1', description: 'Description of Place 1' },
            { name: 'Place 2', description: 'Description of Place 2' },
            ],
            isCarPopUpOpen: false,
            selectedCar: {
                name: "",
                image: "",
                distane: "",
                price: "",
                id: ""
            },
            LocationErrrorPopUp: false,
            InternetErrorPopUp: false,
            rideId:null,
            ordersummarydetails:[],
            fromTripHistoryScreen:false,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recieved", JSON.stringify(message));
        if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
            const rideId = message.getData(
              getName(MessageEnum.SignUpDriverNextData) 
            );
            console.log()
            this.setState({fromTripHistoryScreen:rideId.fromTripHistoryScreen})
            this.handleRideSummary(rideId);
          }
          if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            runEngine.debugLog("API Message Recived", message);

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
              if (apiRequestCallId === this.orderSummaryApiCallId) {
                  if (responseJson && responseJson.data) {
                      this.processResponseData(responseJson);
                  }

              }

        }  
        // Customizable Area End
    }

    // Customizable Area Start
    processResponseData(responseJson:any) {
        this.setState({
            ordersummarydetails: responseJson.data.attributes,
            startlocation: responseJson.data.attributes.start,
            destinationlocation: responseJson.data.attributes.destination
        });
    
        const carName = responseJson.data.attributes.car_type;
        const matchingCar = carData.find((car) => car.name === carName);
    
        if (matchingCar) {
            this.setState({
                selectedCar: {
                    ...matchingCar 
                }
            });
        }
    }
    handleRideSummary(rideId:any) {
        console.log("rideeeeeId",rideId)
        const token = localStorage.getItem("token");
        this.setState({rideId:rideId.data})
        this.orderSummaryApi(token, rideId.data);
    }
    orderSummaryApi = (token: any,rideid:number) => {
        console.log( "kavitakorde5ordersummeryfunction")
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.orderSummaryApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.ordersummaryapi}${rideid}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    handleField3 = () => {
        this.setState({ expandedField3: !this.state.expandedField3 });
    }

    handleField4 = () => {
        this.setState({ expandedField4: !this.state.expandedField4 });
    }

    moveTonextStep = () => {
        const { startlocation, destinationlocation, selectedCar,fromTripHistoryScreen,rideId } = this.state;
        const startlocationerror = !startlocation;
        const endlocationerror = !destinationlocation;
        const cartypeerror = Object.values(selectedCar).some((value) => !value);
        this.setState(
            {
                startlocationerror,
                endlocationerror,
                cartypeerror,
            },
            () => {
                if (!startlocationerror && !endlocationerror && !cartypeerror) {
                    this.props.setactive()
                    const data = {
                        startlocation,
                        destinationlocation,
                        selectedCar,
                        fromTripHistoryScreen,
                        rideId
                    }
                    this.props.planride(data)
                }
            }
        );
    }

    handleClose = () => {
        this.setState({ isToastOpen: false })
    }

    handleField5() {
        this.setState({ expandedField5: !this.state.expandedField5 });
    }
    handleField6() {
        this.setState({ expandedField6: !this.state.expandedField6 });
    }
    onStartDestClose = () => {
        this.setState({ isStartDestOpen: false });
    }

    onContinue = () => {
        if (this.state.startlocation && this.state.destinationlocation) {
            this.setState({ isStartDestOpen: false, showstartlocation: true, showendLocation: true, endlocationerror: false, startlocationerror: false });
        } else {
            this.setState({ isToastOpen: true })
        }
    }
    openStartDestPopup = () => {
        this.setState({ isStartDestOpen: true});

    }
    onCarPopupClose = () => {
        this.setState({ isCarPopUpOpen: false })
    }
    onCarPopupOpen = () => {
        this.setState({ isCarPopUpOpen: true })
    }
    handleCarSelect = (car: any) => {
        this.setState({ selectedCar: car, cartypeerror: false })
        this.onCarPopupClose()
    };
   
    getBackgroundColor = (car: any, selectedCar: any) => {
        return selectedCar === car ? '#F1F5F9' : 'transparent';
    };
    getBorder = (car: any, selectedCar: any) => {
        return selectedCar === car ? '2.48px solid #E2E8F0' : '0px';
    }
    getBorderRadius = (car: any, selectedCar: any) => {
        return selectedCar === car ? '10px' : '0px';
    }

    openLocationErrorPopUp = () => {
        this.setState({ LocationErrrorPopUp: true })
    }
    openInternetErrorPopUp = () => {
        this.setState({ InternetErrorPopUp: true })
    }
    closeLocationErrorPopUp = () => {
        this.setState({ LocationErrrorPopUp: false })
    }
    closeInternetErrorPopUp = () => {
        this.setState({ InternetErrorPopUp: false })
    }
    handleStartLocationChange = (value:any) => {
        const sanitizedValue = value.replace(/[^a-zA-Z0-9\s]/g, '');
        this.setState({ 
            startlocation: sanitizedValue, 
            startlocationerror: sanitizedValue.trim() === '' 
        });
    };
    handleDestinationLocationChange = (value:any) => {
        const sanitizedValue = value.replace(/[^a-zA-Z0-9\s]/g, '');
        this.setState({ 
            destinationlocation: sanitizedValue, 
            endlocationerror: sanitizedValue.trim() === '' 
        });
    };
    // Customizable Area End
}